.app-container {
    min-height: 500px;
}

.footer {
    bottom: 0;
    width: 100%;
    height: 60px; /* Set the fixed height of the footer here */
    line-height: 60px; /* Vertically center the text there */
    background-color: #f5f5f5;
  }

.w-10 {
    width: 10%!important;
}

.dropdown a {
    color: #f5f5f5;
}

a {
    color: black;
}

.btn-link {
    color: black;
}

.bg-purple {
    background-color: rgb(94, 63, 214);
}

.dropdown-menu-purple {
    background-color: rgb(94, 63, 214);
}

body {
    font-family: 'DMSans', cursive;
}

@font-face {
    font-family: 'DMSans';
    src: local('DMSans'), url(./_fonts/DMSans-Regular.ttf) format('truetype');
}